import React from 'react';
import { cx } from 'linaria';
import { footerComponents } from '../../ContentEditor/ContentComponents';
import { useQuery } from 'react-apollo';
import CategoryContentQuery from '../../ContentEditor/DynamicCategoryRenderer/CategoryContentQuery.gql';
import { ContentRenderer } from '@jetshop/ui/ContentRenderer';
import { css } from 'linaria';

const footer = css`
  border: 1px solid red;
`;

const Footer = () => {
  const { data, error } = useQuery(CategoryContentQuery, {
    variables: {
      id: process?.env?.REACT_APP_FOOTER_CATEGORY_ID
    }
  });
  if (!data || error) {
    return null;
  }

  const filterCEItemsWithType = (items, type) => {
    return items?.filter(item => item?.type === type);
  };

  const footerInfo = filterCEItemsWithType(
    data?.category?.data?.items,
    'Footer-1'
  )?.[0];
  const columnLinks = filterCEItemsWithType(
    data?.category?.data?.items,
    'Footer-2-0-Column'
  );
  const paymenMethods = filterCEItemsWithType(
    data?.category?.data?.items,
    'PaymentMethods'
  );

  const newFooterComp = {
    ...footerInfo,
    children: [...footerInfo?.children, ...columnLinks]
  };

  return (
    <div className={cx('footer')}>
      {footerComponents && (
        <ContentRenderer
          items={[newFooterComp, ...paymenMethods]}
          components={footerComponents}
        />
      )}
    </div>
  );
};

export default Footer;
